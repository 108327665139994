<template>
  <div
    v-if="errors.length"
    class="errors"
  >
    <div>
      <div
        v-for="(error, idx) in errors"
        :key="idx"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Errors',

  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
  @import '@/assets/scss/_app.scss';

  .errors {
    @extend .error-banner;
  }
</style>
